<template>
  <v-card :class="darkmode || 'grey lighten-5'" class="ma-4" flat>
    <v-row :class="darkmode || 'white'">
      <v-col cols="12" sm="9">
        <v-tabs v-model="tab" center-active @change="tabValue">
          <v-tab>
            {{ $t("absent.absent_per_day") }}
          </v-tab>
          <v-tab>
            {{ $t("absent.absent_per_subjects") }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-tabs-items v-model="tab" touchless>
      <v-tab-item>
        <v-row>
          <v-col cols="12" sm="8" lg="9">
            <v-radio-group v-model="roleType" row class="pl-2">
              <v-radio :label="$t('routes.student')" value="student" />
              <v-radio :label="$t('routes.teacher')" value="teacher" />
              <v-radio :label="$t('routes.staff')" value="staff" />
            </v-radio-group>
          </v-col>
          <v-col cols="12" sm="4" lg="3" class="d-flex align-center">
            <v-text-field
              v-model="search"
              :label="$t('app.search')"
              :error="!searchValid"
              :hide-details="searchValid"
              :error-messages="
                searchValid ? null : $t('recap_absent.search_valid_rules')
              "
              outlined
              dense
              class="mr-2"
              prepend-inner-icon="mdi-magnify"
            />
          </v-col>
        </v-row>
        <v-spacer />
        <v-divider class="mx-2" />
        <Recap
          v-if="roleStudent"
          :init="roleStudent"
          :name="$t('recap_absent.student')"
          :searchData="search"
          key="student"
          showRoleType
          prefix="student"
          @searchFailed="searchFailed"
        />
        <Recap
          v-if="roleTeacher"
          :init="roleTeacher"
          :name="$t('recap_absent.teacher')"
          :searchData="search"
          key="teacher"
          showRoleType
          employeeType="TEACHER"
          prefix="employee"
        />
        <Recap
          v-if="roleStaff"
          :init="roleStaff"
          :name="$t('recap_absent.staff')"
          :searchData="search"
          key="staff"
          showRoleType
          employeeType="STAFF"
          prefix="employee"
        />
      </v-tab-item>
      <v-tab-item>
        <Header
          :idClass="idClass"
          :idGrade="idGrade"
          :idSchoolYear="idSchoolYear"
          :itemTeacher="itemTeacher"
          class="mt-5"
          @setIdClass="newValue => (idClass = newValue)"
          @setIdGrade="newValue => (idGrade = newValue)"
          @setIdSchoolYear="newValue => (idSchoolYear = newValue)"
          @setTeacher="newValue => (itemTeacher = newValue)"
        />
        <Table
          :idClass="idClass"
          :idGrade="idGrade"
          :idSchoolYear="idSchoolYear"
          :isInitData="initData"
          :itemTeacher="itemTeacher"
          @changeInitData="newValue => (initData = newValue)"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import moment from "moment/moment";
moment.locale("id");

export default {
  components: {
    Recap: () => import("./Recap"),
    Header: () => import("./components/Header"),
    Table: () => import("./components/Table")
  },
  data() {
    return {
      searchValid: true,
      tab: this.$route.query.tab | 0,
      filterAll: [],
      currentDate: moment().format("YYYY-MM-DD"),
      search: "",
      roleType: "student",
      itemTeacher: [],
      initData: false,
      idClass: null,
      idGrade: null,
      idSchoolYear: null
    };
  },
  computed: {
    darkmode() {
      return this.$store.getters.g_darkmode;
    },
    roleStudent() {
      return this.roleType === "student";
    },
    roleTeacher() {
      return this.roleType === "teacher";
    },
    roleStaff() {
      return this.roleType === "staff";
    }
  },
  watch: {
    "$route.query.tab"(n) {
      this.search = "";
      if (n) this.tab = this.$route.query.tab;
    },
    roleType() {
      this.search = "";
    }
  },
  methods: {
    tabValue(tab) {
      this.$router.push({ name: "RecapAbsent", query: { tab: tab } });
    },
    searchFailed(value) {
      if (this.$route.query.tab == 0 || !this.$route.query.tab) {
        this.searchValid = value.text == "" || value.valid ? true : false;
      } else {
        this.searchValid = true;
      }
    }
  }
};
</script>
